@import '~antd/dist/antd.css';

html, body root  {
    width: 100%;
    height: 100%;
    margin: 0;
    overflow: hidden;
    color: black;
  }

body, h1, h2, h3, h4, h5, h6{
    color: black;
}
.ant-tree-node-content-wrapper-open, .ant-tree-node-content-wrapper-close {
    font-weight: bold;
}

.ant-tree li {
    padding-top: 1px;
    padding-bottom: 1px;
}
.ant-tree li .ant-tree-node-content-wrapper {
    color: black;
}
.ant-select-auto-complete {
    width: 100%;
}
.ant-input, .ant-select-dropdown-menu-item {
    color: black;
}

.nnviewer header {
    display: flex;
    flex-direction: row;
    padding-top: 5px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;

}

.nnsearch {
    flex-grow: 1;
    padding-left: 2em;
    padding-top: 8px;
}

.nndetail {
    padding-left: 20px;
    padding-right: 20px;

    padding-top: 8px;
}

.nndetail .definition .structure-name {
    font-style: oblique;
    font-weight: 700;
}
.nndetail th {
    width: 10em;
    vertical-align: top;
}

.nndetail th, td {
    padding-bottom: 0px;
}

.nndetail-indent {
    padding-left: 0em;
}

.nndetail h2 {
    font-weight: 700;
}
.nntree {
    padding-left: 20px;
    padding-top: 4px;
}

.Resizer {
    background: #000;
    opacity: .2;
    z-index: 1;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -moz-background-clip: padding;
    -webkit-background-clip: padding;
    background-clip: padding-box;
}

 .Resizer:hover {
    -webkit-transition: all 2s ease;
    transition: all 2s ease;
}

 .Resizer.horizontal {
    height: 11px;
    margin: -5px 0;
    border-top: 5px solid rgba(255, 255, 255, 0);
    border-bottom: 5px solid rgba(255, 255, 255, 0);
    /* cursor: row-resize; */
    width: 100%;
}

/* .Resizer.horizontal:hover {
    border-top: 5px solid rgba(0, 0, 0, 0.5);
    border-bottom: 5px solid rgba(0, 0, 0, 0.5);
} */

.Resizer.vertical {
    width: 11px;
    margin: 0 -5px;
    border-left: 5px solid rgba(255, 255, 255, 0);
    border-right: 5px solid rgba(255, 255, 255, 0);
    cursor: col-resize;
}

.Resizer.vertical:hover {
    border-left: 5px solid rgba(0, 0, 0, 0.5);
    border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}
